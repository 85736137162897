<template>
	<w-dialog
		v-model="showDialog"
		:max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'"
		no-padding
		:title="$t('document.share.share_document')"
		@close="close()"
	>
		<v-stepper v-model="step">
			<v-stepper-header>
				<v-stepper-step :complete="step > 1" step="1">{{ $t('document.share.first_step') }}</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="2">{{ $t('document.share.last_step') }}</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<w-layout column>
						<w-flex>
							<w-text-info :text="$t('document.share.first_step_info')" />
						</w-flex>
						<w-flex>
							<w-layout v-if="date" align-center row>
								<w-flex v-t="'document.share.share_document_until'" shrink mr-2 />
								<w-flex align-center justify-center shrink>
									<w-date-picker v-model="date" :min="minDate" :clearable="false" />
								</w-flex>
								<w-flex v-t="'document.share.at'" shrink mx-2 />
								<w-flex align-center justify-center shrink>
									<w-time-input v-model="time" />
								</w-flex>
							</w-layout>
						</w-flex>
						<w-flex text-xs-right>
							<w-btn :disabled="!isValid" flat :loading="loading" @click="share()">{{ $t('document.share.actions.next') }}</w-btn>
						</w-flex>
					</w-layout>
				</v-stepper-content>

				<v-stepper-content step="2">
					<w-layout v-t="'document.share.share_document_text'" />
					<w-layout class="pointer primary--text" @click="copyLink()" v-text="shareLink" />
					<w-layout justify-end>
						<w-btn flat @click="copyLink()">{{ $t('document.share.actions.copy') }}</w-btn>
						<w-btn flat @click="sendEmail()">{{ $t('document.share.actions.send_email') }}</w-btn>
					</w-layout>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</w-dialog>
</template>

<script>
import DocumentShareModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentShareModuleGuard'
import { mapState } from 'vuex'

export default {
	name: 'ShareDialog',
	mixins: [DocumentShareModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			date: new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().substr(0, 10),
			minDate: new Date(new Date().getTime()).toISOString().substr(0, 10),
			showDialog: true,
			loading: false,
			menuDate: false,
			time: ('0' + new Date().getHours()).slice(-2) + ':' + ('0' + new Date().getMinutes()).slice(-2),
			shareLink: '',
			step: 0
		}
	},
	computed: {
		...mapState({
			user: state => state.user
		}),
		isValid: function () {
			return this.date && this.time
		}
	},
	methods: {
		close: function () {
			this.showDialog = false
			this.$emit('close')
		},
		copyLink: function () {
			return this.appService.copyToClipboard(this.shareLink).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, this.$t('document.share.url_copied'))
			})
		},
		share: function () {
			this.loading = true
			const datetime = new Date(`${this.date} ${this.time}`).toISOString()
			return this.service
				.createDocumentShare(this.vendorId, this.value, null, { datetime })
				.then(result => (this.shareLink = result.link))
				.then(this.step++)
				.finally(() => {
					this.loading = false
				})
		},
		sendEmail: function () {
			const body = this.$t('document.share.email.body', { document: this.value.name, link: this.shareLink })
			const subject = this.$t('document.share.email.subject', { username: this.user.name })
			window.open(`mailto:?subject=${subject}&body=${body}`, '_blank')
		}
	}
}
</script>
